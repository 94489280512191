<template>
    <div class="inner-section">
        <card>
            <template v-slot:searchHeaderTitle>
                <h4 class="card-title">{{$t('externalTraining.faq_List')}}</h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('externalTraining.faq_name')"
                        label-for="faq_setup"
                        >
                        <v-select name="faq_setup"
                        v-model="search.id"
                        label="text"
                        :reduce="item => item.value"
                        :options= faqSetupLists
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button size="sm" variant="primary" @click="searchData">
                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('externalTraining.faq_List')}}</h4>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                    <select-column-check :labelData="labelData" :search="search" :columns="columns" />
                    <b-row>
                        <b-col md="12" class="table-responsive">
                            <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                <template v-slot:cell(index)="data">
                                {{ $n(data.index + 1) }}
                                </template>
                            </b-table>
                            <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :perPage="search.limit"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
    </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { faqSetupList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
        return {
            search: {
                id: 0,
                limit: 20
            },
            labelData: [
                { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
                { labels: 'elearning_config.faq_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
                { labels: 'externalTraining.answer', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } }
            ]
        }
    },
    computed: {
        faqSetupLists: function () {
          return this.$store.state.ExternalUserService.trainingPanel.commonObj.faqSetupList.filter(item => item.status === 1)
        },
        columns () {
            const labelData = this.labelData
            const labels = labelData.map((item, index) => {
                return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
            })

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'faq_name_bn' },
                { key: 'description_bn' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'faq_name' },
                { key: 'description' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    watch: {
        'search.limit': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadData()
            }
        }
    },
     mounted () {
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, faqSetupList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList',response.data.data)
                    this.paginationData(response.data.data, this.search.limit)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
